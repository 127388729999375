<template>
  <div class="qrcode-ico"></div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      uniqueNo: '',
      tp: ''
    };
  },
  methods: {},
  mounted() {},
  created() {
    this.uniqueNo = this.$route.query.code;
    this.tp = this.$route.query.tp;
    if (this.tp && this.tp.toLowerCase() === 'u') {
      //个人二维码
      window.location.href =
        '//mshop.jinyingu.cn/user/info/userinfo?desUId=' + this.uniqueNo;
    } else if (this.tp && this.tp.toLowerCase() === 'c') {
      //企业二维码
      //window.location.href ='//mshop.jinyingu.cn/user/info/userinfo?com=1&desUId=' + this.uniqueNo;
    } else if (this.tp && this.tp.toLowerCase() === 'upat') {
      //用户企业、岗位、职称二维码
      window.location.href =
        '//mshop.jinyingu.cn/user/info/userinfo?com=1&desUId=' + this.uniqueNo;
    } else {
      window.location.href =
        '//mshop.jinyingu.cn/goods/detail?uniqueno=' + this.uniqueNo;
    }
  }
};
</script>
<style scoped></style>
